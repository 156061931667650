import React from 'react';
import '../pages/Home/Home.css';
import code from '../assets/img/home/code.jpg';
import gamedev from '../assets/img/home/gamedev.png';
import { Link } from 'react-router-dom';

const HomeContent = () => {
    return (
      <div className="home-content">
        <div className="header">
          <h1>Portfolio</h1>
        </div>

        <div className="pt-4">
          <div className="container-fluid">
            <div className="card-row justify-content-center pb-5">
              <div className='px-3 pb-2' style={{ display: "inline-block" }}>
                <Link
                  to="/software-dev"
                  aria-label="Developer Page"
                  className="img-hover"
                >
                  <h3>Software Development</h3>
                  <p>View experience and projects</p>
                  <img
                    className="content-img"
                    src={code}
                    aria-label="Code"
                  />
                </Link>
              </div>
              <div className='px-3 pb-2' style={{ display: "inline-block" }}>
                <Link to="/game-dev"
                  aria-label="Game Developer Page"
                  className="img-hover"
                >
                  <h3>Game Development</h3>
                  <p>Game projects, prototypes and experience</p>
                  <img
                    className="content-img"
                    src={gamedev}
                    aria-label="Games"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HomeContent;