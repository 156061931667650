import React from "react";
import placeholder from "../assets/img/placeholder.png";
import godot from "../assets/img/technologies/godot.png";
import unity from "../assets/img/technologies/unity.png";
import unreal from "../assets/img/technologies/unreal.png";
import blender from "../assets/img/technologies/blender.png";
import cs from "../assets/img/technologies/cs.png";
import react from "../assets/img/technologies/react.png";
import vue from "../assets/img/technologies/vue.png";
import firebase from "../assets/img/technologies/firebase.png";
import fastapi from "../assets/img/technologies/fastapi.png";
import mysql from "../assets/img/technologies/mysql.png";
import fcc from "../assets/img/technologies/fcc.png";
import nextjs from "../assets/img/technologies/nextjs.png";

const TechLogo = ({ name }) => {
    const images = [
        {
            name: "Godot",
            image: godot,
            alt: "Godot Logo",
        },
        {
            name: "Unity",
            image: unity,
            alt: "Unity Logo",
        },
        {
            name: "Unreal",
            image: unreal,
            alt: "Unreal Logo",
        },
        {
            name: "Blender",
            image: blender,
            alt: "Blender Logo",
        },
        {
            name: "C#",
            image: cs,
            alt: "C# Logo",
        },
        {
            name: "React",
            image: react,
            alt: "React Logo",
        },
        {
            name: "Vue",
            image: vue,
            alt: "Vue Logo",
        },
        {
            name: "Firebase",
            image: firebase,
            alt: "Firebase Logo",
        },
        {
            name: "FastAPI",
            image: fastapi,
            alt: "FastAPI Logo",
        },
        {
            name: "MySQL",
            image: mysql,
            alt: "MySQL Logo",
        },
        {
            name: "freeCodeCamp",
            image: fcc,
            alt: "freeCodeCamp Logo",
        },
        {
            name: "NextJS",
            image: nextjs,
            alt: "NextJS Logo",
        }
    ];

    let selectedImage = images.find((image) => image.name === name);

    if(selectedImage === undefined) {
        selectedImage = {
            name: "placeholder",
            image: placeholder,
            alt: "Placeholder Logo",
        }
    }
    
    return (
        <div>
            <img className="tec-logo" src={selectedImage.image} alt={selectedImage.alt} />
        </div>
    );
};

export default TechLogo;
