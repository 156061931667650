import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GameItem from "../../components/GameItem";
import "./GameDev.css";

import azra1 from "../../assets/img/projects/games/azra1.png";
import azra1_thumbnail from "../../assets/img/projects/games/thumbnails/azra1.jpg";
import azra2 from "../../assets/img/projects/games/azra2.png";
import azra2_thumbnail from "../../assets/img/projects/games/thumbnails/azra2.jpg";

import azravideo from "../../assets/video/azravideo.mp4"
import azravideo_thumbnail from "../../assets/img/projects/games/thumbnails/azra-video.jpg";

import pongvideo from "../../assets/video/pongvideo.mp4";
import pongvideo_thumbnail from "../../assets/img/projects/games/thumbnails/pong-video.jpg";
import runnervideo from "../../assets/video/runnervideo.mp4";
import runnervideo_thumbnail from "../../assets/img/projects/games/thumbnails/runner-video.jpg";

import vhhb1 from "../../assets/img/projects/games/vhhb1.png";
import vhhb1_thumbnail from "../../assets/img/projects/games/thumbnails/vhhb1.jpg";
import vhhb2 from "../../assets/img/projects/games/vhhb2.png";
import vhhb2_thumbnail from "../../assets/img/projects/games/thumbnails/vhhb2.jpg";
import vhhb3 from "../../assets/img/projects/games/vhhb3.png";
import vhhb3_thumbnail from "../../assets/img/projects/games/thumbnails/vhhb3.jpg";
import vhhb4 from "../../assets/img/projects/games/vhhb4.png";
import vhhb4_thumbnail from "../../assets/img/projects/games/thumbnails/vhhb4.jpg";

import dungeon1 from "../../assets/img/projects/games/dungeon1.png";
import dungeon1_thumbnail from "../../assets/img/projects/games/thumbnails/dungeon1.jpg";
import dungeonvideo from "../../assets/video/dungeon-video.mp4";
import dungeonvideo_thumbnail from "../../assets/img/projects/games/thumbnails/dungeon-video.jpg";
import wfcdemo from "../../assets/img/projects/games/wfc-demo.gif";
import wfcdemo_thumbnail from "../../assets/img/projects/games/thumbnails/wfc-demo.jpg";


import plat_godot_video1_thumbnail from "../../assets/img/projects/games/thumbnails/3dplat_godot1.jpg";

import plat3d_video from '../../assets/video/plat3d_video.mp4';

const gameData = [
  {
    id: 1,
    title: "3D Platformer Prototypes",
    role: "Solo Dev",
    date: "2023 - Present",
    description:["Created a 3D platformer prototype to learn about player movement and reactive enviroment.","Implemented in Unity and Godot to learn how differnt engines handle 3D physics."],
    features: ["Learned about the physics that go into 3D character controllers and enviroments.", "Learned about 3D camera systems and physics."],
    technologies: ["Unity", "Godot"],
    images: [
      {
        original: plat_godot_video1_thumbnail,
        thumbnail: plat_godot_video1_thumbnail,
        embedUrl: plat3d_video,
      }
    ],
  },
  {
    id: 2,
    title: "Dungeon Crawler Game Jam",
    role: "Programmer, Direction, 3D Artist",
    date: "2023 (Cancelled)",
    description:["Unfinished turn based Dungeon Crawler RPG game for Dungeon Crawler Game Jam.","Use items, fight, barter with NPC, and explore your way through a randomly generated dungeon to find the exit."],
    features: ["Randomly generated NPC appearence and personalities", "Implemented a branching-heavy Dialogue System","Inventory System with usable Magic Spells and Items with unique effects"],
    technologies: ["Unity", "C#", "Blender"],
    images: [
      {
        original: vhhb1,
        thumbnail: vhhb1_thumbnail,
      },
      {
        original: vhhb2,
        thumbnail: vhhb2_thumbnail,
      },
      {
        original: vhhb3,
        thumbnail: vhhb3_thumbnail,
      },
      {
        original: vhhb4,
        thumbnail: vhhb4_thumbnail,
      }
    ],
  },
  {
    id: 3,
    title: "3D Dungeon Generator Prototypes",
    role: "Solo Dev",
    date: "2023",
    description:
      ["Learned about random generation in Unity.","Created a random 3D dungeon generator, also experimented with map generation using Wave Function Collapse."],
    features: ["Learned about random generation in Unity"],
      technologies: ["Unity", "C#", "Blender"],
    images: [
      {
        original: dungeon1,
        thumbnail: dungeon1_thumbnail,
      },
      {
        original: dungeonvideo_thumbnail,
        thumbnail: dungeonvideo_thumbnail,
        embedUrl: dungeonvideo,
      },
      {
        original: wfcdemo,
        thumbnail: wfcdemo_thumbnail,
      }
    ],
    demo: "https://xaqui.github.io/procedural-dungeon/Demo/"
  },
  {
    id: 4,
    title: "Simple Game Prototypes",
    role: "Solo Dev",
    date: "2022 - 2023",
    description:["Made a few simple games to learn about game development and Game Engines. Learned about game mechanics, physics, and UI.","Some examples include a Pong-like game and an Endless Runner."],
    features: ["Learned basic concepts about Game Design", "Learned about game management and UI", "Learned about shaders and particle effects"],
    technologies: ["Unity", "C#"],
    images: [
      {
        original: pongvideo_thumbnail,
        thumbnail: pongvideo_thumbnail,
        embedUrl: pongvideo,
      },
      {
        original: runnervideo_thumbnail,
        thumbnail: runnervideo_thumbnail,
        embedUrl: runnervideo,
      }
    ],
  },
  {
    id: 5,
    title: "Azra",
    role: "Programmer - Team Panda",
    date: "2022",
    description:["Participated in Team Panda as a developer and helped program an exhibition build of their upcoming game, Azra."],
    features: ["Programmed Player Movement, Combat, and Abilities","Built custom Dialogue System", "Programmed User Interface"],
    technologies: ["Unity", "C#"],
    images: [
      {
        original: azravideo_thumbnail,
        thumbnail: azravideo_thumbnail,
        embedUrl: azravideo,
      },
      {
        original: azra1,
        thumbnail: azra1_thumbnail,
      },
      {
        original: azra2,
        thumbnail: azra2_thumbnail,
      },

    ],
  },

];

function GameDev() {
  return (
    <div>
      <Header />
      <div className="header">
        <h2 className="mx-auto text-center">Projects</h2>
      </div>
      <div className="container-bg">
        <div className="wrapper mx-auto py-5">
          <div className="games-row">
            {gameData.map((game, index) => (
              <GameItem
              key={game.id}
                index={index}
                title={game.title}
                role={game.role}
                date={game.date}
                description={game.description}
                features={game.features}
                technologies={game.technologies}
                images={game.images}
                demo={game.demo}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GameDev;
