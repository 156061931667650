import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "./css/Header.css";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <div id="navName">
              <span>Joaquín Barreiro</span>
            </div>
          </a>
          <div className="mx-3">
            <button
              className="navbar-toggler p-1"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarToggler"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="d-flex justify-content-center align-items-center">
                <Link to="/">Home</Link>
              </li>
              <li className="d-flex justify-content-center align-items-center">
                <Link to="/software-dev">Software Dev</Link>
              </li>
              <li className="d-flex justify-content-center align-items-center">
                <Link to="/game-dev">Game Dev</Link>
              </li>
              <li className="social-icons">
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/joaqu%C3%ADn-barreiro-garc%C3%ADa-b66239242/"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/xaqui"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    <FontAwesomeIcon icon={faGithub} size="2x" />
                  </a>
                  <a
                    href="mailto:joaquin@joaquinbarreiro.com"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;