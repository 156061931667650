import React from 'react';
import ImageGallery from 'react-image-gallery';
import video from '../assets/video/movie.mp4';
import azra1 from "../assets/img/projects/games/azra1.png";

const PREFIX_URL =
    '';

class MyVideoSlider extends React.Component {
    constructor() {
        super();
        this.state = {
            showIndex: false,
            showBullets: true,
            infinite: true,
            showThumbnails: true,
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showGalleryPlayButton: true,
            showNav: true,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            slideOnThumbnailOver: false,
            thumbnailPosition: 'bottom',
            showVideo: {},
            useWindowKeyDown: true,
        };

        this.images = [
            {
                thumbnail: `https://i3.ytimg.com/vi/sGvh-WJjK60/hqdefault.jpg`,
                original: `https://i3.ytimg.com/vi/sGvh-WJjK60/maxresdefault.jpg`,
                embedUrl:
                    video,
                description: 'Render custom slides (such as videos)',
                renderItem: this._renderVideo.bind(this),
            },
            {
                original: azra1,
                thumbnail: azra1,
                originalClass: 'featured-slide',
                thumbnailClass: 'featured-thumb',
                description: 'Custom class for slides & thumbnails',
            },
        ]
    }



    _handleInputChange(state, event) {
        if (event.target.value > 0) {
            this.setState({ [state]: event.target.value });
        }
    }

    _handleCheckboxChange(state, event) {
        this.setState({ [state]: event.target.checked });
    }

    _handleThumbnailPositionChange(event) {
        this.setState({ thumbnailPosition: event.target.value });
    }

    _getStaticImages() {
        let images = [];
        for (let i = 2; i < 4; i++) {
            images.push({
                original: `${PREFIX_URL}${i}.jpg`,
                thumbnail: `${PREFIX_URL}${i}t.jpg`,
            });
        }

        return images;
    }

    _resetVideo() {
        this.setState({ showVideo: {} });

        if (this.state.showPlayButton) {
            this.setState({ showGalleryPlayButton: true });
        }

        if (this.state.showFullscreenButton) {
            this.setState({ showGalleryFullscreenButton: true });
        }
    }


    _renderVideo(item) {
        return (
            <div>
                <video autoPlay muted loop playsInline>
                    <source src={item.embedUrl} type="video/mp4" />
                </video>

            </div>
        );
    }

    render() {
        const { 
            items = this.images,
            showIndex = this.state.showIndex, 
            showBullets = this.state.showBullets, 
            infinite = this.state.infinite, 
            showFullscreenButton = this.state.showFullscreenButton,
            showPlayButton = this.state.showPlayButton,
            showThumbnails = this.state.showThumbnails,
            showNav = this.state.showNav,
            isRTL = this.state.isRTL,
            thumbnailPosition = this.state.thumbnailPosition,
            slideDuration = parseInt(this.state.slideDuration),
            slideInterval = parseInt(this.state.slideInterval),
            slideOnThumbnailOver = this.state.slideOnThumbnailOver,
            useWindowKeyDown = this.state.useWindowKeyDown
        } = this.props;

        const updatedItems = items.map(item => {
            if (item.embedUrl) {
                return {
                    ...item,
                    renderItem: this._renderVideo.bind(this)
                };
            }
            return item;
        });

        return (
            <section className="app">
                <ImageGallery
                    ref={(i) => (this._imageGallery = i)}
                    items={updatedItems}
                    infinite={infinite}
                    showBullets={showBullets}
                    showFullscreenButton={showFullscreenButton}
                    showPlayButton={showPlayButton}
                    showThumbnails={showThumbnails}
                    showIndex={showIndex}
                    showNav={showNav}
                    isRTL={isRTL}
                    thumbnailPosition={thumbnailPosition}
                    slideDuration={parseInt(slideDuration)}
                    slideInterval={parseInt(slideInterval)}
                    slideOnThumbnailOver={slideOnThumbnailOver}
                    additionalClass="app-image-gallery"
                    useWindowKeyDown={useWindowKeyDown}
                />
            </section>
        );
    }
}

export default MyVideoSlider;
