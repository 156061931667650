import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SoftwareItem from "../../components/SoftwareItem";
import "./SoftwareDev.css";

const softwareDate = [
  {
    id: 1,
    title: "Mini Social Media App",
    date: "2023 - Present (WIP)",
    description: "Web application consisting of a backend that implements CRUD operations using a REST model in FastAPI and a frontend made with Vue3. It recreates some features of popular microblogging social networks with a didactic purpose. It incorporates login with Firebase and a SQL database.",
    technologies: ["Vue", "FastAPI", "Firebase"],
    github: null
  },
  {
    id: 2,
    title: "GodotCore",
    date: "2023",
    description:
      "Port of UnityCore, a small utility framework for game development from Unity to Godot 4. It implements some basic utilities such as scene switching, menu system, audio system, data persistence system and Available in C# and GDScript.",
    technologies: ["Unity","C#", "Godot"],
    github: "https://github.com/xaqui/GodotCore/"
  },
  {
    id: 3,
    title: "freeCodeCamp Certifications",
    date: "2023",
    description: "Obtanied the following certifications from freeCodeCamp: Back End Development and APIs, Foundational C# with Microsoft.",
    technologies: ["freeCodeCamp","C#","NextJS"],
    github: null
  },
];

function SoftwareDev() {
  return (
    <div>
      <Header />
      <div className="header">
        <h2 className="mx-auto text-center">Projects and Experience</h2>
      </div>
      <div className="container-bg">
        <div className="wrapper mx-auto py-5">
          <div className="soft-row">
            {softwareDate.map((software) => (
              <SoftwareItem
              key={software.id}
                title={software.title}
                role={software.role}
                date={software.date}
                description={software.description}
                technologies={software.technologies}
                github={software.github}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SoftwareDev;
