import React from "react";
import "../pages/GameDev/GameDev.css";
import "react-image-gallery/styles/css/image-gallery.css";
import TechLogo from "./TechLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import MyVideoSlider from "./MyVideoSlider";

function GameItem({
  title,
  role,
  date,
  description,
  features,
  index,
  technologies,
  images,
  demo
}) {
  const isEven = index % 2 === 0;
  const textColumnClass = isEven ? "order-md-1" : "order-md-2";
  const imageColumnClass = isEven ? "order-md-2" : "order-md-1";
  const showThumbnail = images.length > 1;

  let button;
  if (demo != null && demo !== undefined) {
    button = (
      <div className=" pb-4">
        <a target="_blank" rel="noreferrer" href={demo}>
          <button className="btn btn-secondary github-button" type="button">
            <div className="d-flex justiy-content-center align-items-center">
              <FontAwesomeIcon icon={faGamepad} size="2x" />
              <span className="px-3">Try live demo on browser</span>
            </div>
          </button>
        </a>
      </div>
    );
  }

  return (
    <div className="game-experience">
      <h3 className="mx-auto text-center pt-3 ">{title}</h3>
      <div className="card-row px-0">
        <div className={`col-md-6 ${textColumnClass} game-info my-auto`}>
          <h3 className="role mx-auto text-center pb-1">{role}</h3>
          <hr/>
          <p className="date mx-auto text-center pb-4">{date}</p>
          {description.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <ul>
          {features.map((paragraph, index) => (
            <li key={index}>{paragraph}</li>
          ))}
          </ul>
          <div className="d-flex justify-content-center pt-4">
            {technologies.map((technology, index) => (
              <div key={index} className="px-1">
                <TechLogo name={technology} />
              </div>
            ))}
          </div>
            <div className="text-center mt-4">{button}</div>
        </div>
        <div className={`col-md-6 ${imageColumnClass} game-media mx-auto d-flex justify-content-center`}>
          <div className="image-gallery">
          <MyVideoSlider
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            slideInterval={6000}
            slideDuration={500}
            showNav={false}
            showThumbnails={showThumbnail}
            showBullets={false}
            lazyLoad={true}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameItem;