import './App.css';
import AppRoutes from './Routes';
import React from 'react';
import './Fonts.css';


function App() {
  return (
    <div>
        <AppRoutes />
    </div>
  );
}

export default App;
