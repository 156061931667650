import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HomeContent from "../../components/HomeContent";
import "./Home.css";
import BackgroundParticles from "../../components/BackgroundParticles";
import avatar from '../../assets/img/profile/avatar.png';
import MyVideoSlider from "../../components/MyVideoSlider";

function Home() {
  return (
    <div>
      <div id="home-body">
      <Header />
        <div id="intro-background">
          <BackgroundParticles />
        </div>
        <div id="intro-overlay">
          <div id="intro">
            <div className="profile-card">
              <div className="container">
                <div className="card-row justify-content-center">
                  <div className="inner">
                    <div className="card-image">
                      <img src={avatar} alt="" />
                    </div>
                    <div className="main-text">
                      <h2>Joaquín Barreiro</h2>
                      <p>Software Developer</p>
                    </div>
                    {/* <div className="social-icons">
                      <FontAwesomeIcon icon={faLinkedin} />
                      <FontAwesomeIcon icon={faGithub} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeContent />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
