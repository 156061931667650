import React from "react";
import "../pages/SoftwareDev/SoftwareDev.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import TechLogo from "./TechLogo";

function SoftwareItem({
  title,
  date,
  description,
  technologies,
  github,
}) {
  let button;
  if (github != null && github !== undefined) {
    button = (
      <div className=" pb-4">
        <a target="_blank" rel="noreferrer" href={github}>
          <button className="btn btn-secondary github-button" type="button">
            <div className="d-flex justiy-content-center align-items-center">
              <FontAwesomeIcon icon={faGithub} size="2x" />
              <span className="px-3">View on GitHub</span>
            </div>
          </button>
        </a>
      </div>
    );
  }

  return (
    <div className="container ">
      <div className="soft-experience">
      <h3 className="text-center pt-4 ">{title}</h3>
      <div className=" px-5">
        <div className="col d-flex justify-content-center">
          <div className="soft-info">
            <p className="text-center pb-4 date">{date}</p>
            <p className="pb-4">{description}</p>
            <div className="pb-3">
              <div className="py-auto d-flex justify-content-center align-items-center">
                {technologies.map((technology, index) => (
                  <div key={index} className="px-1">
                    <TechLogo name={technology} />
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-3">{button}</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default SoftwareItem;
